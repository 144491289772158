.jss_charts {
    display: flex;
    justify-content: center;
    align-items: center;
    outline: #ccc solid 1px;
    padding: 20px;
}

.jss_charts input,
.jss_charts select,
.jss_charts .lm-dropdown-input {
    padding: 8px;
}

.jss_charts_type i {
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 15px;
}

.jss_charts_type input {
    display: none;
}

.jss_charts_type input:checked + i {
    outline: 2px solid black;
}

.jss_charts_error:after {
    content: attr(data-error);
    position: absolute;
    font-size: 12px;
    color: darkgray;
}

.jss_charts_config_fullscreen {
    width: 100% !important;
    height: 100% !important;
    top: 0;
    left: 0;
    transform: none;
    border: 0;
    border-radius: 0;
}

.jss_charts_config_fullscreen .jss_charts_config_content {
    overflow-y:auto;
}

.jss_charts_config input.jcolor-input {
    display: none;
}

.jss_charts_config .color-bg {
    width: 100%;
    height: 34px;
    border: 1px solid #CCC;
    display: inline-block;
    border-right: none;
}

.jss_charts_floating {
    position: fixed;
    display: none;
    background-color: #fff;
    padding: 20px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
    box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
    box-sizing: border-box;
    width: 300px;
    height: 300px;
    z-index: 1;
}

.jss_charts_floating.active {
    display: block;
}

.jss_charts_palette {
    display: flex;
    padding-top: 8px;
    padding-bottom: 8px;
}

.jss_charts_palette > div {
    width: 12px;
    height: 12px;
    margin: 2px;
    border: 1px solid #000;
}

.jss_charts_button {
    font-size: 0.8em;
    background-color: transparent;
    border: 0;
    margin: 0;
    padding: 6px;
    text-align: left;
    cursor: pointer;
}

.jss_charts_series i {
    cursor: pointer;
    margin: 2px;
}